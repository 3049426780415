<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading" style="opacity: 0.85;"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>

        </div>
        <div>&nbsp;<img src="../../../../src/assets/img/disdag.png" height="40"></div>
        <br><br><span class="text-danger">..Mohon Tunggu, Proses Menyambungkan Data SiHarpa...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                Pantauan Harga Perdagangan Harian Kota Semarang
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    Pantauan Harga Pasar
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">
        &nbsp;
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <center><span class="text-danger" id="tunggu">..Mohon Tunggu, Proses Menyambungkan Data SiHarpa...</span></center>
        <div class="col-sm-12 float-left pb-2 wow fadeInDown">
            <input type="text" v-model="carikata" class="form-control form-control-sm"
                placeholder="Masukkan Kata Kunci Pencarian">
        </div>
        <div class="table-responsive table-wrapper">
            <table class="table m-0" id="tabeldata">
                <thead>
                    <tr>
                        <th style="width: 30px;" rowspan="2">No</th>
                        <th style="width: 50px;" rowspan="2">Ketagori<br>Bahan Pokok</th>
                        <th style="width: 50px;" rowspan="2">Jenis<br>Bahan Pokok</th>
                        <th style="width: 50px;" rowspan="2">Satuan</th>
                        <th colspan="2">Harga Terendah</th>
                        <th colspan="2">Harga Tertinggi</th>
                    </tr>
                    <tr>
                        <th style="width: 50px;">Pasar</th>
                        <th style="width: 50px;">Harga</th>
                        <th style="width: 50px;">Pasar</th>
                        <th style="width: 50px;">Harga</th>
                    </tr>
                </thead>
                <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                    <tbody>
                        <tr>
                            <td class="text-center">{{ urutlist + 1 }}</td>
                            <td class="text-justify">
                                {{ datalist.nama_sub_kategori_bapok }}
                            </td>
                            <td class="text-justify">
                                {{ datalist.nama_bapok }}
                            </td>
                            <td class="text-justify">
                                {{ datalist.satuan }}
                            </td>
                            <td class="text-justify">
                                {{ datalist.min_namapasar }}
                            </td>
                            <td class="text-right">
                                {{ G_numFormat(datalist.min_harga) }}
                            </td>
                            <td class="text-justify">
                                {{ datalist.max_namapasar }}
                            </td>
                            <td class="text-right">
                                {{ G_numFormat(datalist.max_harga) }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </table>
        </div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datamaster: [],
            carikata: '',
            username: 'adit.nuryono@gmail.com',
            password: 'gispas4r',
            tokennya: '',
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        ambildata() {
            document.getElementById('tunggu').style.display='block';
            this.halamanloading = true;
            var fd = new FormData();
            fd.append("username", this.username);
            fd.append("password", this.password);
            const mainAPI = axios.create({
                baseURL: 'https://siharpa.semarangkota.go.id',
                headers: {
                    "x-api-key": '83EE832293447382FB329D44D2BF9446'
                },
            });
            mainAPI.post("/api/user/login", fd).then(
                Response => {
                    const mainAPIHARGA = axios.create({
                        baseURL: 'https://siharpa.semarangkota.go.id',
                        headers: {
                            "x-api-key": '83EE832293447382FB329D44D2BF9446',
                            "X-Token": Response.data.token,
                        },
                    });
                    this.halamanloading = true;
                    mainAPIHARGA.get("/api/dashboard_bahan_pokok_monthly/info_harga_pasar").then(
                        Response => {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'success',
                                title: 'Berhasil mengambil ' + Response.data.message,
                            });
                            this.datamaster = [];
                            this.datamaster = Response.data.data.info_harga_harian;
                            this.halamanloading = false;
                            document.getElementById('tunggu').style.display='none';
                        }
                    ).catch(error => {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                        this.datamaster = [];
                        this.halamanloading = false;
                    });
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
                this.halamanloading = false;
            }
            )

        },

    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>