<template>
    <footer class="main-footer">
        <strong>Copyright {{ tahun }} .</strong>
        All rights reserved.
        <div class="float-right d-none d-sm-inline-block">
            <b>Version</b> 3.0.0
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            tahun: new Date().getFullYear(),
        }
    }
}
</script>

<style></style>