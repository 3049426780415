<template>
    <!-- Copyright Start -->
    <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="text-white mb-4 pmisputih">SIBALOK</h4>
                        <p>Sistem Informasi Monitoring & Evaluasi Data Pengadaan Bahan Logistik</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-2">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            Jumlah Pengunjung
                        </h4>
                        <a href="#"><i class="fas fa-angle-right me-2"></i>
                            Hari ini: {{ G_numFormat(harini) }}
                        </a>
                        <a href=""><i class="fas fa-angle-right me-2"></i>
                            Bulan ini: {{ G_numFormat(bulanini) }}
                        </a>
                        <a href=""><i class="fas fa-angle-right me-2"></i>
                            Tahun ini: {{ G_numFormat(tahunini) }}
                        </a>
                        <a href=""><i class="fas fa-angle-right me-2"></i>
                            Total: {{ G_numFormat(totalpengunjung) }}
                        </a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            Tautan
                        </h4>
                        <a href="https://semarangkota.go.id/" target="_blank"><i
                                class="fas fa-angle-right me-2"></i> Kota Semarang</a>
                        <a href="https://dpu.semarangkota.go.id/" target="_blank"><i
                                class="fas fa-angle-right me-2"></i> DPU Kota Semarang</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-4">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            Info Kontak
                        </h4>
                        <a href=""><i class="fas fa-house-user me-2"></i> Dinas Pekerjaan Umum Kota Semarang</a>
                        <a href=""><i class="fa fa-map-marker-alt me-2"></i> Jl. Madukoro Raya No.7, Krobokan, Semarang
                            Barat
                            Kota Semarang, Jawa Tengah 50141</a>
                        <a href="" style="font-size: 15px;"><i class="fas fa-envelope me-2"></i>
                            dpu.smgkota@gmail.com</a>
                        <a href=""><i class="fas fa-phone me-2"></i> 024-76433969</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid copyright py-4" style="background-color: #111 !important;">
        <div class="container">
            <div class="row g-6 align-items-center">
                <div class="col-md-4 text-center text-md-start mb-md-0">
                    <span class="text-white">
                        <a href="#" class="merahmaroon">
                            <i class="fas fa-copyright text-light me-2"></i> SIBALOK
                        </a>
                        {{ tahun }} All right reserved.
                    </span>
                </div>
                <div class="col-md-12 text-center text-md-start mb-md-0 text-white">
                    Designed By <a href="https://dpu.semarangkota.go.id/" target="_blank"><span
                            class="merahmaroon">DINAS PEKERJAAN UMUM KOTA SEMARANG</span> </a>,
                    Contributed By
                    <a href="https://parastapa.online/" target="_blank"><span class="merahmaroon">T.K - Parastapa
                            Technology</span></a>
                </div>
            </div>
        </div>
    </div>
    <!-- Copyright End -->
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

export default {
    components: {
        swal
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            harini: 0,
            bulanini: 0,
            tahunini: 0,
            totalpengunjung: 0,
        };
    },
    methods: {
        async ambildata() {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationVisitor-GetVisitor?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.harini = Response.data.hariini;
                        this.bulanini = Response.data.bulanini;
                        this.tahunini = Response.data.tahunini;
                        this.totalpengunjung = Response.data.total;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>