<template>
    <Top />
    <center>
        <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <iframe :src="sourcenyamapdetails" class="col-sm-12 text-center" style="min-height: 65vh;"
                            frameborder="0" id="mapdetails" @load="loaddetails()" scrolling="no"></iframe>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-danger btn-sm text-white lebar" data-dismiss="modal"><i
                                class="fas fa-window-close"></i> Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </center>
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumbpeta">

    </div>
    <!-- Header End -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <input type="hidden" class="form-control form-control-sm" id="detail" name="detail"
                        placeholder="Detail">
                    <input type="hidden" class="form-control form-control-sm" id="Latitude" name="Latitude"
                        placeholder="Latitude">
                    <input type="hidden" class="form-control form-control-sm" id="Longitude" name="Longitude"
                        placeholder="Longitude">
                    <button type="button" class="btn btn-tool" id="details" @click="details()" style="display: none;"><i
                            class="fas fa-minus text-white"></i></button>
                    <button type="button" class="btn btn-tool" id="kosong" @click="datakosong()"
                        style="display: none;"><i class="fas fa-minus text-white"></i></button>
                    <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 95vh;" frameborder="0"
                        id="maps" scrolling="no" @load="loadpeta()"></iframe>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: '',
            sourcenyamapdetails: '',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        };
    },
    methods: {
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya + '|' + this.secretencData;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        details() {
            this.halamanloading = true;
            const message = this.linknya + '|' + document.getElementById('detail').value + '|' + document.getElementById('Latitude').value + '|' + document.getElementById('Longitude').value;
            const iframe = document.getElementById("mapdetails");
            this.sourcenyamapdetails = '';
            this.sourcenyamapdetails = '/details.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
            $('#costumModaldisclaimer').modal('show');
        },
        loaddetails() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.getElementById("mapdetails");
            this.sourcenyamapdetails = '';
            this.sourcenyamapdetails = '/details.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Layer Gagal Diunduh..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
    },
    mounted() {
        this.halamanloading = true;
    },
}
</script>

<style></style>