<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <center>
        <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        Data Lapak Pedagang
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 float-left pb-2">
                            <div class="row">
                                <div class="col-sm-2 float-left">
                                    Total Data: {{ CountData }}
                                </div>
                                <div class="col-sm-2 float-left">
                                    Total Terisi: {{ CountIsi }}
                                </div>
                                <div class="col-sm-2 float-left">
                                    Total Kosong: {{ CountKosong }}
                                </div>
                                <div class="col-sm-2 float-left">
                                    Keterisian: {{ PersenKeterisian }} %
                                </div>
                                <div class="col-sm-2 float-left">
                                    Pelanggar: {{ CountPelanggar }}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 float-left pb-2">
                            <input type="text" v-model="carikatadetail" class="form-control form-control-sm"
                                placeholder="Masukkan Kata Kunci Pencarian">
                        </div>
                        <div class="table-responsive table-wrapper">
                            <table class="table m-0" id="tabeldatadetail">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">No</th>
                                        <th style="width: 50px;">Kode Lapak</th>
                                        <th style="width: 50px;">Ukuran Lapak</th>
                                        <th style="width: 50px;">Luas Lapak</th>
                                        <th style="width: 100px;">Komoditi</th>
                                        <th style="width: 50px;">Jenis<br>Dasaran</th>
                                        <th style="width: 100px;">Lokasi</th>
                                        <th style="width: 100px;">Foto</th>
                                    </tr>
                                </thead>
                                <template v-for="(datalist, urutlist) in filteredKataDatadetail" :key="urutlist">
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{ urutlist + 1 }}</td>
                                            <td class="text-justify">
                                                {{ datalist.e_pendawa_kode }}
                                            </td>
                                            <td class="text-justify">
                                                {{ datalist.Ukuran }}
                                            </td>
                                            <td class="text-justify">
                                                {{ datalist.Luas }}
                                            </td>
                                            <td class="text-justify">
                                                {{ datalist.JenisJualan }}
                                            </td>
                                            <td class="text-justify">
                                                {{ datalist.JenisDasaran }}
                                            </td>
                                            <td class="text-justify">
                                                {{ datalist.Latitude }}<br>
                                                {{ datalist.Longitude }}
                                            </td>
                                            <td class="text-center">
                                                <img :src="datalist.URLFotoLapak" class="img-size-75 animation__wobble"
                                                    alt="Lapak Image" style="height: 100px;">
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-danger btn-sm text-white lebar" data-dismiss="modal"><i
                                class="fas fa-window-close"></i> Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </center>
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                DAFTAR LAPAK PEDAGANG
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    Data Pedagang
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">
        &nbsp;
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="col-sm-12 float-left pb-2 wow fadeInDown">
            <input type="text" v-model="carikata" class="form-control form-control-sm"
                placeholder="Masukkan Kata Kunci Pencarian">
        </div>
        <div class="table-responsive table-wrapper">
            <table class="table m-0" id="tabeldata">
                <thead>
                    <tr>
                        <th style="width: 50px;">No</th>
                        <th style="width: 150px;">Nama Korwil</th>
                        <th style="width: 150px;">Kode Pasar</th>
                        <th style="width: 150px;">Nama Pasar</th>
                        <th style="width: 100px;">Status Data</th>
                    </tr>
                </thead>
                <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                    <tbody>
                        <tr>
                            <td class="text-center">{{ urutlist + 1 }}</td>
                            <td class="text-justify">
                                {{ datalist.NamaKorwil }}
                            </td>
                            <td class="text-justify">
                                {{ datalist.KodePasar }}
                            </td>
                            <td class="text-justify">
                                {{ datalist.NamaPasar }}
                            </td>
                            <td class="text-center">
                                <template v-for="(dataLantaiPasar, urutLantaiPasar) in datalist.LantaiPasar"
                                    :key="urutLantaiPasar">
                                    <span v-if="dataLantaiPasar.NamaLantai == 'Belum terdata'">{{
                                        dataLantaiPasar.NamaLantai }}</span>
                                    <button v-if="dataLantaiPasar.NamaLantai != 'Belum terdata'" type="button"
                                        class="btn btn-danger btn-sm text-white" style="width: 350px;"
                                        @click="lihatlokasi(dataLantaiPasar.TabelDetailLantai)"><i
                                            class="fas fa-map-marker-alt"></i>
                                        Lihat Data {{ dataLantaiPasar.NamaLantai }}
                                    </button><br><br>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </table>
        </div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datamaster: [],
            carikata: '',
            carikatadetail: '',
            datamasterdetail: [],
            CountData: 0,
            CountIsi: 0,
            CountKosong: 0,
            PersenKeterisian: 0,
            CountPelanggar: 0,
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        filteredKataDatadetail() {
            const filteredWorkersdetail = this.carikatadetail === ""
                ? this.datamasterdetail
                : this.datamasterdetail.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatadetail.toLowerCase()) !== -1);
            return filteredWorkersdetail;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        async lihatlokasi(lokasi) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationDaftar-CountLapak?random=" + random + "&tabel=" + lokasi).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterdetail = [];
                        this.datamasterdetail = Response.data.content.data;
                        this.CountData = Response.data.CountData;
                        this.CountIsi = Response.data.CountIsi;
                        this.CountKosong = Response.data.CountKosong;
                        this.PersenKeterisian = Response.data.PersenKeterisian;
                        this.CountPelanggar = Response.data.CountPelanggar;
                        this.carikatadetail = '';

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamasterdetail = [];
                this.halamanloading = false;
            });
            $('#costumModaldisclaimer').modal('show');
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationDaftar-LantaiPasar?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        }
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>