<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                POTENSI PERDAGANGAN
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    Data Potensi Pasar
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">
        &nbsp;
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="col-sm-12 float-left pb-2 wow fadeInDown">
            <input type="text" v-model="carikata" class="form-control form-control-sm"
                placeholder="Masukkan Kata Kunci Pencarian">
        </div>
        <div class="table-responsive table-wrapper">
            <table class="table m-0" id="tabeldata">
                <thead>
                    <tr>
                        <th style="width: 30px;">No</th>
                        <th style="width: 50px;">Korwil</th>
                        <th style="width: 150px;" colspan="12">Nama Pasar</th>
                    </tr>
                </thead>
                <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                    <tbody>
                        <tr>
                            <td class="levelsubsubkegiatan text-center">{{ urutlist + 1 }}</td>
                            <td class="levelsubsubkegiatan text-justify">
                                {{ datalist.NamaKorwil }}
                            </td>
                            <td class="levelsubsubkegiatan text-justify" colspan="12">
                                {{ datalist.NamaPasar }}
                            </td>
                        </tr>
                        <tr>
                            <td class="levelakhir text-center" colspan="2" rowspan="2" style="width: 100px;">Nama Lantai</td>
                            <td class="levelakhir text-center" style="width: 50px;" rowspan="2">Total Lapak</td>
                            <td class="levelakhir text-center" style="width: 50px;" colspan="3">Kios</td>
                            <td class="levelakhir text-center" style="width: 50px;" colspan="3">Los</td>
                            <td class="levelakhir text-center" style="width: 50px;" rowspan="2">Tingkat<br>Keterisian<br>(%)</td>
                            <td class="levelakhir text-center" style="width: 50px;" rowspan="2">Potensi<br>Retribusi<br>(Rp.)</td>
                            <td class="levelakhir text-center" style="width: 50px;" rowspan="2">Target<br>Retribusi<br>(Rp.)</td>
                            <td class="levelakhir text-center" style="width: 50px;" rowspan="2">Total<br>Retribusi<br>(Rp.)</td>
                        </tr>
                        <tr>
                            <td class="levelakhir text-center" style="width: 50px;">Jumlah</td>
                            <td class="levelakhir text-center" style="width: 50px;">Isi</td>
                            <td class="levelakhir text-center" style="width: 50px;">Kosong</td>
                            <td class="levelakhir text-center" style="width: 50px;">Jumlah</td>
                            <td class="levelakhir text-center" style="width: 50px;">Isi</td>
                            <td class="levelakhir text-center" style="width: 50px;">Kosong</td>
                        </tr>
                        <template v-for="(datapotensi, urutpotensi) in datalist.LantaiPasar" :key="urutpotensi">
                            <tr>
                                <td class="text-center">{{ urutlist + 1 }}.{{ urutpotensi + 1 }}</td>
                                <td class="text-justify">{{ datapotensi.NamaLantai }}</td>
                                <td class="text-center">{{ datapotensi.JumlahLapak }}</td>
                                <td class="text-center">
                                    <span v-if="datapotensi.NamaLantai != 'Belum terdata'">
                                        {{ datapotensi.JumlahKiosKosong + datapotensi.JumlahKiosIsi }}
                                    </span>
                                    <span v-else>Belum terdata</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="datapotensi.NamaLantai != 'Belum terdata'">
                                        {{ datapotensi.JumlahKiosIsi }}
                                    </span>
                                    <span v-else>Belum terdata</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="datapotensi.NamaLantai != 'Belum terdata'">
                                        {{ datapotensi.JumlahKiosKosong }}
                                    </span>
                                    <span v-else>Belum terdata</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="datapotensi.NamaLantai != 'Belum terdata'">
                                        {{ datapotensi.JumlahLosKosong + datapotensi.JumlahLosIsi }}
                                    </span>
                                    <span v-else>Belum terdata</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="datapotensi.NamaLantai != 'Belum terdata'">
                                        {{ datapotensi.JumlahLosIsi }}
                                    </span>
                                    <span v-else>Belum terdata</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="datapotensi.NamaLantai != 'Belum terdata'">
                                        {{ datapotensi.JumlahLosKosong }}
                                    </span>
                                    <span v-else>Belum terdata</span>
                                </td>
                                <td class="text-center">{{ datapotensi.Persenketerisian }}</td>
                                <td class="text-right">{{ datapotensi.PotensiPasar }}</td>
                                <td class="text-right">{{ datapotensi.TargetPasar }}</td>
                                <td class="text-right">{{ datapotensi.RetribusiPasar }}</td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </table>
        </div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datamaster: [],
            carikata: '',
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationPotensi-Pasar?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        }
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>